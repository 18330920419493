<template>
  <div class="w-full">
    <h1 class="visually-hidden">
      {{ seoTitleH1 }}
    </h1>
    <BlocksContainer
      :components="pageData?.blocks || []"
      :page-data="pageData"
    />

    <!-- <PagesHomeBlockHeadImageSwiper :data="pageData?.blocks[0].content" /> -->
    <!-- <PagesHomeBlockShopByCategory :data="pageData?.blocks[1].content" /> -->
    <!-- <PagesHomeBlockNowTrendingNow :data="pageData?.blocks[2].content" /> -->
    <!-- <PagesHomeBlockHomeSlides :data="pageData?.blocks[3].content" /> -->
    <!-- <PagesHomeBlockHomeCollectionNewArrivals
      :data="pageData?.blocks[4].content"
    /> -->
    <!-- <PagesHomeBlockHomeCollectionNewArrivals
      :data="pageData?.blocks[5].content"
    /> -->
    <!-- <PagesHomeBlockHomeAlwaysEngaging :data="pageData?.blocks[6].content" /> -->
    <!-- <PagesHomeBlockHomeMediaSlider :data="pageData?.blocks[7].content" /> -->
    <!-- <PagesHomeBlockShopByDiamondShape :data="pageData?.blocks[8].content" /> -->
    <!-- <PagesHomeBlockHomeLabGrownDiamonds :data="pageData?.blocks[9].content" /> -->
    <!-- <PagesHomeBlockHomeDesignYouEngagementRings
      :data="pageData?.blocks[11].content"
    /> -->
    <!-- <PagesHomeBlockHomeYouPremierFineSlider
      :data="pageData?.blocks[13].content"
    /> -->
  </div>
</template>

<script lang="ts" setup>
import { metaGenerator } from "~/utils";
import { useGeneralStore } from "~/stores/general";
import { useJsonLdSchema } from "~/composables";

const { t } = useI18n();
const { $api } = useNuxtApp();
const generalStore = useGeneralStore();
const config = useRuntimeConfig();
const i18nHead = useLocaleHead({
  addSeoAttributes: true,
});
const { setHomePageJsonLdSchema } = useJsonLdSchema();
// const { setBreadcrumbs } = useBreadcrumbs();

const { integrationCallbackStatuses, gaDynamicParamsEvent } =
  useGoogleAnalyticsEvents();
const { visitEvent } = useEvents();

const { data } = await useAsyncCachedData("home", () => {
  return $api.pages.getPage("home");
});

const pageData = data.value?.data;
useHead({
  meta: [
    ...metaGenerator({
      title: pageData?.currentTranslation?.meta_title || "",
      description: pageData?.currentTranslation?.meta_description || "",
      image:
        getImageByPosition(pageData?.medias || [], "open-graph")?.file?.url ||
        "",
      url: pageData?.currentTranslation?.meta_url || config.public.domain,
    }),
    ...(i18nHead.value.meta || []),
  ],
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  // title:
  //   pageData?.currentTranslation?.meta_title ||
  //   pageData?.currentTranslation?.title ||
  //   "",
});

const { seoTitle, seoTitleH1, seoDescription } = usePageSeo();

useSeoMeta({
  title: () => seoTitle.value,
  ogTitle: () => seoTitle.value,
  description: () =>
    pageData?.currentTranslation?.meta_description || seoDescription.value,
  ogDescription: () =>
    pageData?.currentTranslation?.meta_description || seoDescription.value,
});

setHomePageJsonLdSchema({
  name: pageData?.currentTranslation?.title || "",
  image:
    getImageByPosition(pageData?.medias || [], "open-graph")?.file?.url ||
    getImageByPosition(pageData?.medias || [], "page")?.file?.url ||
    "",
});

watchEffect(() => {
  if (integrationCallbackStatuses.value.TYPE_GOOGLE_ANALYTICS) {
    gaDynamicParamsEvent("View Home", {
      ecomm_prodid: "",
      ecomm_pagetype: "home",
      ecomm_totalvalue: "",
      ecomm_category: "",
    });
  }
});

onMounted(() => {
  visitEvent(pageData?.is_plp);
});

function usePageSeo() {
  const seoTitle = computed(() => {
    let title =
      pageData?.currentTranslation?.meta_title ||
      pageData?.currentTranslation?.title ||
      "";
    if (generalStore.settings?.company_name) {
      title = `${title} | ${generalStore.settings.company_name}`;
    }
    return title;
  });

  const seoTitleH1 = computed(() => pageData?.currentTranslation?.title || "");

  const seoDescription = computed(() => {
    const title = pageData?.currentTranslation?.title || "";
    return `${title} ${t("pageMetaDescription")}`;
  });
  return {
    seoTitle,
    seoTitleH1,
    seoDescription,
  };
}
</script>
